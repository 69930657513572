import React, { createContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

interface JivoWidgetContextType {
  addJivoWidget: () => void
  removeJivoWidget: () => void
}

const JivoWidgetContext = createContext<JivoWidgetContextType | undefined>(
  undefined
)

const EXCLUDED_PATHS = ['/package', '/hotel']

export const JivoWidgetProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const location = useLocation()

  const addJivoWidget = () => {
    const jdivElements = document.getElementsByTagName(
      'jdiv'
    ) as HTMLCollectionOf<HTMLElement>

    for (let i = 0; i < jdivElements.length; i++) {
      jdivElements[i].style.display = ''
    }
  }

  const removeJivoWidget = () => {
    const jdivElements = document.getElementsByTagName(
      'jdiv'
    ) as HTMLCollectionOf<HTMLElement>

    for (let i = 0; i < jdivElements.length; i++) {
      jdivElements[i].style.display = 'none'
    }
  }

  useEffect(() => {
    if (EXCLUDED_PATHS.includes(location.pathname)) {
      removeJivoWidget()
    } else {
      addJivoWidget()
    }
  }, [location.pathname])

  return (
    <JivoWidgetContext.Provider value={{ addJivoWidget, removeJivoWidget }}>
      {children}
    </JivoWidgetContext.Provider>
  )
}
